.image-gallery-left-nav .image-gallery-svg, 
.image-gallery-right-nav .image-gallery-svg {
  width: 50px;
  height: 50px; 

}
.image-gallery-content .image-gallery-slide img {
  width: 100%;
  height: auto;}

